import React, {useEffect} from "react";
import {RC_AVAILABITY_LIST, RESET_REDUCER_KEYS, RC_AVAILABITY_TYPES} from "../../utils/app-constants";
import SelectComponent from "../shared/select";
const rcAvailabilityDependentFields = {
    [RC_AVAILABITY_TYPES.ORIGINIAL]: ['rcCondition', 'misMatchInRc'],
    [RC_AVAILABITY_TYPES.DUPLICATE]: ['rcCondition', 'misMatchInRc'],
    [RC_AVAILABITY_TYPES.PHOTOCOPY]: ['rcCondition', 'misMatchInRc', "rcAtDelivery"],
    [RC_AVAILABITY_TYPES.LOST]: [],
};
const RcAvailability = ({
    detail,
    inspectionDetails,
    resetInspectionDetailByKeyConnect,
}) => {
    const {key, value, text, isEditable=false} = detail;
    const { documentsMapper } = inspectionDetails;
    const defaultValueObj = RC_AVAILABITY_LIST.find(field=> field.value === value);
    
    useEffect(()=> {
        onRcDeliverySelected(value);
    },[value]);

    const onRcDeliverySelected = (value) => {
        let mapper = [...documentsMapper];
        const keyIndex = mapper.findIndex(item => item.key === key);
        mapper[keyIndex].newValue = value;
        mapper[keyIndex].newId = value;
        if (value === RC_AVAILABITY_TYPES.PHOTOCOPY) {
            mapper[keyIndex].dependentFields.push('rcAtDelivery');
        }
        const dependantFields = rcAvailabilityDependentFields[value];
        mapper[keyIndex].dependentFields.map(fieldKey => {
            const keyIndex = mapper.findIndex(item => item.key === fieldKey);
            const isEditableRequired = dependantFields.includes(fieldKey);
            if (keyIndex > -1) {
                mapper[keyIndex].isEditable = isEditableRequired;
                mapper[keyIndex].isVisible = isEditableRequired;
                mapper[keyIndex].checked = isEditableRequired;
            }
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };
    const onSelect = (value) => {
        onRcDeliverySelected(value);
    };

    return (
        <div>
            {isEditable ? <SelectComponent 
                defaultValue={defaultValueObj}
                optionsList={RC_AVAILABITY_LIST}
                placeholder={text}
                onChange={onSelect}
            /> : value}
        </div>
    );
};

export default RcAvailability;
